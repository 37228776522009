import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"
import { MediaQueries } from "../../utils/responsive"

const StyledCalloutComponent = styled(Col)`
  margin-bottom: ${props => `${props.theme.margins.verticalMobile}px`};
  @media ${MediaQueries.mobile} {
    margin: ${props => `${props.theme.margins.verticalDesktop}px auto`};
  }
  p,
  a {
    margin: 0;
    font-size: 2.8rem;
    color: ${props => props.theme.colors.pink};
    font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    span {
      color: ${props => props.theme.colors.purple};
      a {
        text-decoration: underline;
        color: ${props => props.theme.colors.purple};
        &:hover {
          text-decoration: none;
        }
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`

const HomeCalloutComponent = props => {
  return (
    <Container className="mt-5 mt-md-0">
      <Row>
        <StyledCalloutComponent xs={12}>
          <SanityBlockContent
            key={(Math.random() * 1000 + 12).toString()}
            blocks={props.callout}
            projectId={sanityClientConfig.sanity.projectId}
            dataset={sanityClientConfig.sanity.dataset}
            imageOptions={{ w: 200, h: 100, fit: "max" }}
            serializers={sanitySerializers}
          />
        </StyledCalloutComponent>
      </Row>
    </Container>
  )
}

HomeCalloutComponent.propTypes = {
  callout: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default HomeCalloutComponent
