import React from "react"
import AutoCounter from "../Counter"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import WasteIcon from "../../images/svg/hulladek2.svg"
import SavingsIcon from "../../images/svg/megtakaritas3.svg"
import { MediaQueries } from "../../utils/responsive"

const StyledCounterDiv = styled.div`
  position: relative;
  h2 {
    margin: 0;
    font-size: 2rem;
    text-transform: uppercase;
    color: ${props => props.theme.colors.purple};
  }
  .content-holder {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon-holder {
    width: 15%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin: 1rem;
    svg {
      width: 40px;
      height: auto;
      @media ${MediaQueries.tablet} {
        width: 55px;
      }
    }
  }
  .text-holder {
    width: 70%;
    .my-link {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .counter-holder {
    color: ${props => props.theme.colors.pink};
    font-size: 2rem;
    div {
      display: inline-block;
      font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      @media ${MediaQueries.tablet} {
        font-size: 4.8rem;
        line-height: 1;
        margin: 1rem auto;
      }
    }
    .measure-holder {
      display: inline-block;
      margin-left: 1rem;
      margin-top: 1rem;
      font-size: 1.6rem;
      @media ${MediaQueries.tablet} {
        font-size: 2.8rem;
        margin-left: 2rem;
        margin-top: 0;
        font-family: "Asap", -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      }
    }
  }
`

const HomeCounterHolder = props => {
  return (
    <StyledCounterDiv>
      <StaticImage
        style={{
          gridArea: "1/1",
          zIndex: -1,
          // You can set a maximum height for the image, if you wish.
        }}
        alt="logo"
        placeholder="blurred"
        src={"../../images/home_small_bg.png"}
        formats={["auto", "webp", "avif"]}
      />
      <div className="content-holder">
        <div className="icon-holder">
          {props.counterIndex > 0 ? <WasteIcon /> : <SavingsIcon />}
        </div>
        <div className="text-holder">
          <div>
            <Link
              to={`/${props.language}/tarsadalmi-hatasunk`}
              activeClassName={"active"}
              className={"my-link"}
            >
              <h2>{props.text}</h2>
            </Link>
          </div>
          <div className="counter-holder">
            <AutoCounter
              baseCount={props.baseCount}
              increaseCount={props.increaseCount}
              decimals={props.decimals}
            />
            <span className="measure-holder">{props.measure}</span>
          </div>
        </div>
      </div>
    </StyledCounterDiv>
  )
}

HomeCounterHolder.propTypes = {
  baseCount: PropTypes.number.isRequired,
  increaseCount: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  measure: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default HomeCounterHolder
