export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateName = name => {
  return name && name.length > 3
}

export const validateAccept = accept => {
  return accept === true
}

export const validateFields = (setErrorMsg, formData, errorMsgs) => {
  const isNameValid = validateName(formData.name)
  const isEmailValid = validateEmail(formData.email)
  const isAcceptValid = validateAccept(formData.accept)

  if (!isNameValid) {
    setErrorMsg(prevState => {
      return { ...prevState, name: errorMsgs.name }
    })
    document.querySelector('[name="name"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="name"]')?.classList.remove("is-invalid")
    document.querySelector('[name="name"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, name: null }
    })
  }
  if (!isEmailValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        email: errorMsgs.email,
      }
    })
    document.querySelector('[name="email"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="email"]')?.classList.remove("is-invalid")
    document.querySelector('[name="email"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, email: null }
    })
  }

  if (!isAcceptValid) {
    setErrorMsg(prevState => {
      return {
        ...prevState,
        accept: `${errorMsgs.accept}`,
      }
    })
    document.querySelector('[name="accept"]')?.classList.add("is-invalid")
  } else {
    document.querySelector('[name="accept"]')?.classList.remove("is-invalid")
    document.querySelector('[name="accept"]')?.classList.add("is-valid")
    setErrorMsg(prevState => {
      return { ...prevState, accept: null }
    })
  }
  return isNameValid && isEmailValid && isAcceptValid
}
