import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import isBrowser from "../../utils/isBrowser"

/**
 * TODO???
 * https://decembersoft.com/posts/using-timer-in-react-component/
 * "Hey, why not use useState() to hold the timer handle?" you might ask...
 * Well sure, you could. But then anything that depends on the timer would
 * be recalculated each time the timer is set.
 * It's just cleaner to use an immutable ref so that there are
 * no unnecessary redraws.
 */

const prettifyNumber = (count, decim) => {
  // 1. ADD thousand separators

  // split the number and remove decimals
  const num2 = count.toFixed(2).split(".")
  // add "." separator between thousands
  const thousands = num2[0]
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(".")

  // 2. ADD decimals, prettify digits

  let decimalPart = num2[1]
  // if num2[1] is NOT undefined / if decimal is single digit add 0, or return decimal
  if (num2[1]) {
    decimalPart = num2[1].length === 1 ? `${decimalPart}0` : decimalPart
  } else {
    // if num2[1] is undefined return 00
    decimalPart = `00`
  }

  // 3. ADD a separator before the decimal part
  const prettyDecimals = decim > 0 ? (decimalPart ? "," + decimalPart : "") : ""

  return thousands.split("").reverse().join("") + prettyDecimals
}

const AutoCounter = ({ baseCount, baseInterval, increaseCount, decimals }) => {
  // 1 Set base date
  const baseDate = new Date(2021, 5, 1).getTime() / 1000 // sec
  // 2 Get base volume from props
  // 3 Get per second increase from props

  // Set volume (count) on state
  const [count, setCount] = useState(baseCount)

  useEffect(() => {
    // 4 get date / check isBrowser -> get from browser date?
    let currentDate
    currentDate = isBrowser && Date.now() / 1000 // sec
    // 5 calculate difference in time in seconds
    const timeDifference = Math.floor(
      Math.floor(currentDate) - Math.floor(baseDate)
    )
    let interval = null
    // Register a function to increment the count in the given interval
    // and capture the timer handle so we can cancel it later.
    const currentValue = baseCount + increaseCount * timeDifference

    interval = setInterval(() => {
      setCount(prettifyNumber(currentValue, decimals))
    }, baseInterval)
    // Clean up when the component is unmounting
    // i.e. equivalent to componentWillUnmount() in a class
    return () => {
      clearInterval(interval)
    }
  }, [count, setCount])
  return <div>{count}</div>
}

AutoCounter.propTypes = {
  baseCount: PropTypes.number,
  baseInterval: PropTypes.number,
  increaseCount: PropTypes.number,
  decimals: PropTypes.number,
}

AutoCounter.defaultProps = {
  baseCount: 0,
  baseInterval: 1000,
  decimals: 0,
}

export default AutoCounter
