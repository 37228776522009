import React from "react"
import AutoCounter from "../Counter"
import styled from "styled-components"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { MediaQueries } from "../../utils/responsive"

const StyledCounterDiv = styled.div`
  position: relative;
  h2 {
    margin: 0;
    font-size: 2rem;
    text-transform: uppercase;
    color: ${props => props.theme.colors.purple};
  }
  .content-holder {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon-holder {
    width: 15%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin: 1rem;
    svg {
      width: 40px;
      height: auto;
      @media ${MediaQueries.tablet} {
        width: 55px;
      }
    }
  }
  .text-holder {
    width: 70%;
    h2 {
      color: ${props => props.theme.colors.pink};
      font-size: 4rem;
      font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      letter-spacing: 2px;
      font-weight: normal;
      @media ${MediaQueries.tablet} {
        font-size: 4.8rem;
        line-height: 1;
        margin: 1rem auto;
      }
    }
  }
`

const NewsletterCalloutButton = props => {
  return (
    <StyledCounterDiv>
      <StaticImage
        style={{
          gridArea: "1/1",
          zIndex: -1,
          // You can set a maximum height for the image, if you wish.
        }}
        alt="logo"
        placeholder="blurred"
        src={"../../images/home_small_bg.png"}
        formats={["auto", "webp", "avif"]}
      />
      <div className="content-holder">
        <div className="icon-holder">
          <StaticImage
            src="../../images/cseriti_newsletter_icon.png"
            alt="newsletter icon"
          />
        </div>
        <div className="text-holder">
          <div>
            <h2>Hírlevél</h2>
          </div>
        </div>
      </div>
    </StyledCounterDiv>
  )
}

export default NewsletterCalloutButton
