import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { getImage } from "gatsby-plugin-image"
import HomeBackground from "../components/Visuals/homeBackground"
import JumboComponent from "../components/JumboComponent"
import HomeVisionComponent from "../components/HomeVisionComponent"
import HomeCounterHolder from "../components/HomeCounterHolder"
import HomeIntroComponent from "../components/HomeIntro"
import HomeCardHolder from "../components/HomeCardHolder"
import HomeCalloutComponent from "../components/HomeCalloutComponent"
import HomeContactComponent from "../components/HomeContactComponent"
// import NewsletterModal from "../components/NewsletterModal"
import NewsletterCalloutButton from "../components/NewsletterModal/NewsLetterCalloutButton"
import NewsletterForm from "../components/NewsletterModal/NewsletterForm"

const IndexPage = props => {
  const { i18n } = useTranslation("home")
  const language = i18n.language

  const baseWaste = 2500000 // kg
  const baseSaving = 490000000 // Ft

  const increaseSaving = 3.2 // Ft/s
  const increaseWaste = 0.02 // kg/s
  const pageData = props.data.sanityHomePage

  const seoImage = getImage(
    pageData?.mainImages[0]?.asset || pageData?.mainImages[1]?.asset
  )?.images.fallback.src

  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <Container>
        <Seo
          title="Akár adsz, akár veszel, mindenképpen jót teszel"
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={pageData?.mainImages}
          messages={pageData?.mainMessages}
          slogan={pageData?._rawSlogan?.[language]}
        />
        <HomeVisionComponent vision={pageData?._rawVision?.[language]} />
        <Row>
          <Col xs={12} sm={6}>
            <HomeCounterHolder
              baseCount={baseSaving}
              increaseCount={increaseSaving}
              text={pageData?.megtakaritas?.translate}
              measure={"Ft"}
              counterIndex={0}
              decimals={0}
              language={language}
            />
          </Col>
          <Col xs={12} sm={6}>
            <HomeCounterHolder
              baseCount={baseWaste}
              increaseCount={increaseWaste}
              text={pageData?.wasteReduction?.translate}
              measure={"kg"}
              counterIndex={1}
              decimals={1}
              language={language}
            />
          </Col>
        </Row>
      </Container>
      <HomeIntroComponent intro={pageData?._rawIntro?.[language]} />
      <HomeCardHolder cards={pageData.cards} language={language} />
      <Container>
        <Row>
          <Col xs={12} md={6} className="pb-5 pb-lg-0">
            <NewsletterCalloutButton />
          </Col>
          <Col xs={12} md={6} className="pb-5 pb-lg-0">
            <NewsletterForm />
          </Col>
        </Row>
      </Container>
      <HomeCalloutComponent callout={pageData?._rawCallout?.[language]} />
      <HomeContactComponent
        intro={pageData?._rawIntro?.[language]}
        language={language}
      />
      {/* <NewsletterModal /> */}
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery($language: String) {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawCallout
      _rawIntro
      _rawSlogan
      _rawVision
      megtakaritas {
        translate(language: $language)
      }
      wasteReduction {
        translate(language: $language)
      }
      mainMessages {
        _rawTitle
      }
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      cards {
        _rawBoxDescription
        icon {
          asset {
            id
            gatsbyImageData
            extension
            path
          }
        }
        title {
          translate(language: $language)
        }
      }
    }
  }
`
export default IndexPage
