import React, { useState } from "react"
import axios from "axios"
import { Link } from "gatsby"
import styled from "styled-components"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import { validateFields } from "./validation"

// Styled submit button
const StyledButton = styled(Button)`
  margin-top: 3rem;
  background-color: ${props => props.theme.colors.purple};
  text-align: center;
  width: 300px;
  border-radius: 40px;
  font-family: Anton;
  font-size: 2.8rem;
  padding: 2rem;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;

  &:hover {
    background-color: ${props => props.theme.colors.pink};
  }
`

const StyledForm = styled(Form)`
  font-size: 2rem;
  .form-check-input {
    margin-left: -2.25rem;
  }
  .form-control {
    padding: 1.5rem 0.75rem;
    font-size: 2rem;
  }
`

const SubmittedWrapper = styled.div`
  h2 {
    margin-top: 3rem;
  }
`

const NewsletterForm = ({ setIsVisible }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    leadActions: false,
    purchaseActions: false,
    accept: false,
  })

  const [submitted, setSubmitted] = useState(false)

  const handleChange = e => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    })
  }

  const [errorMsg, setErrorMsg] = useState({
    name: null,
    email: null,
    accept: null,
  })

  const errorMsgs = {
    name: "Add meg a neved!",
    email: "Adj meg egy érvényes e-mail címet!",
    accept: "Fogadd el az Adatkezelési tájékoztatót!",
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // Define error messages

    const isValid = validateFields(setErrorMsg, formData, errorMsgs)

    if (isValid && formData.accept) {
      try {
        await axios.post("/.netlify/functions/newsletter-signup", formData)
        if (setIsVisible) {
          setSubmitted(true)
          setTimeout(() => setIsVisible(false), 3000)
        }
        setSubmitted(true)
      } catch (error) {
        console.error("Error submitting form", error)
      }
    }
  }

  return !submitted ? (
    <StyledForm>
      <FormGroup>
        <Label for="name">Név *</Label>
        <Input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errorMsg.name && (
          <span className="text-danger d-block">{errorMsg.name}</span>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="email">E-mail *</Label>
        <Input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errorMsg.email && (
          <span className="text-danger d-block">{errorMsg.email}</span>
        )}
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="leadActions"
            checked={formData.leadActions}
            onChange={handleChange}
          />
          Leadói akciók és információk érdekelnek
        </Label>
        <Label check>
          <Input
            type="checkbox"
            name="purchaseActions"
            checked={formData.purchaseActions}
            onChange={handleChange}
          />
          Vásárlói akciók és információk érdekelnek
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="accept"
            checked={formData.accept}
            onChange={handleChange}
            required
          />
          Az{" "}
          <Link to="/hu/adatvedelem" style={{ textDecoration: "underline" }}>
            Adatkezelési tájékoztatóban
          </Link>{" "}
          foglaltakat megismertem, az adataim kezeléséhez hozzájárulok. *
        </Label>
        {errorMsg.accept && (
          <span className="text-danger d-block">{errorMsg.accept}</span>
        )}
      </FormGroup>
      <StyledButton color="primary" type="submit" onClick={handleSubmit}>
        Feliratkozom
      </StyledButton>
    </StyledForm>
  ) : (
    <SubmittedWrapper className="submitted-wrapper">
      <h2>
        Köszönjük, hogy feliratkoztál! Hamarosan találkozunk a postafiókodban
        is! :-)
      </h2>
    </SubmittedWrapper>
  )
}

export default NewsletterForm
