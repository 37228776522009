import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"
import { MediaQueries } from "../../utils/responsive"

const StyledVisionComponent = styled(Col)`
  margin: ${props => `${props.theme.margins.verticalMobile}px auto`};
  @media ${MediaQueries.mobile} {
    margin-top: ${props => `${props.theme.margins.verticalDesktop}px`};
    margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
  }
  p {
    margin: 0;
    font-size: 2.8rem;
    color: ${props => props.theme.colors.pink};
    font-family: "Anton", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif,
      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    span {
      color: ${props => props.theme.colors.purple};
    }
  }
`

const HomeVisionComponent = props => {
  return (
    <Row>
      <StyledVisionComponent xs={12}>
        <SanityBlockContent
          key={(Math.random() * 1000 + 12).toString()}
          blocks={props.vision}
          projectId={sanityClientConfig.sanity.projectId}
          dataset={sanityClientConfig.sanity.dataset}
          imageOptions={{ w: 200, h: 100, fit: "max" }}
          serializers={sanitySerializers}
        />
      </StyledVisionComponent>
    </Row>
  )
}

HomeVisionComponent.propTypes = {
  vision: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default HomeVisionComponent
