import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { MediaQueries } from "../../utils/responsive"
import Card from "../Card"

const StyledCardHolder = styled(Container)`
  display: flex;
  margin: ${props => `${props.theme.margins.verticalMobile}px auto`};
  @media ${MediaQueries.mobile} {
    margin: ${props => `${props.theme.margins.verticalDesktop}px auto`};
  }
`

const HomeCardHolder = props => {
  return (
    <StyledCardHolder>
      <Row>
        {props.cards.map((c, i) => (
          <Col
            xs={12}
            sm={6}
            lg={4}
            key={c.icon.asset.id}
            className="pb-5 pb-lg-0"
          >
            <Card
              image={
                c.icon.asset.extension === "svg"
                  ? `https://cdn.sanity.io/${c.icon.asset.path}`
                  : c.icon.asset.gatsbyImageData
              }
              title={c.title.translate}
              description={c._rawBoxDescription[props.language]}
              index={i}
            />
          </Col>
        ))}
      </Row>
    </StyledCardHolder>
  )
}

export default HomeCardHolder
