import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"
import styled from "styled-components"
import { MediaQueries } from "../../utils/responsive"

const StyledIntroComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
  margin: ${props => `${props.theme.margins.verticalMobile}px 0`};
  .introHolder {
    margin: 5rem auto;
    p {
      text-align: justify;
      a {
        color: ${props => props.theme.colors.pink};
        font-size: 1.8rem;

        text-decoration: underline;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  iframe {
    height: 300px;
  }
  @media ${MediaQueries.tablet} {
    height: auto;
    iframe {
      height: 400px;
    }
  }
  @media ${MediaQueries.desktop} {
    height: 465px;
    margin: ${props => `${props.theme.margins.verticalDesktop * 2}px auto`};
    .introHolder {
      padding-left: 5rem;
    }
    iframe {
      height: 100%;
    }
  }
`
const HomeIntroComponent = props => {
  return (
    <StyledIntroComponent>
      <StaticImage
        style={{
          gridArea: "1/1",
          zIndex: -1,
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
        }}
        alt=""
        placeholder="blurred"
        src={"../../images/Hattercsik.jpg"}
        formats={["auto", "webp", "avif"]}
      />
      <Container className="h-100">
        <Row className="h-100">
          <Col xs={12} lg={8} className="h-100 d-flex px-0 px-xl-4">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Anqj4Q5JvPw"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
          <Col xs={12} lg={4} className="introHolder">
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={props.intro}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
        </Row>
      </Container>
    </StyledIntroComponent>
  )
}

HomeIntroComponent.propTypes = {
  intro: PropTypes.arrayOf(PropTypes.object.isRequired),
}

export default HomeIntroComponent
